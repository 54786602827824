/* eslint-disable */
import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
// import { faChevronRight } from '@witbe/pro-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { useTranslation } from 'react-i18next';
// import Button from '@witbe/ui-react-components/dist/Button';
import styles from './styles/404.module.scss';
import newStyles from './styles/new404.module.scss';
import { Layout } from '../components/common';
import Banner from '../images/404-bg.png';
import BannerMobile from '../images/404-mobile-bg.png';
import { useViewport } from '../hooks';
import SEO from '../components/common/SEO';

export default function NotFoundPage(props) {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <>
        <Layout title="Page Not Found - Witbe">
            <SEO
                title="Page Not Found - Witbe"
                ogDescription="Oops, there is an error in the link that you are trying to access, try using our site map to discover where you can go"
                description="Oops, there is an error in the link that you are trying to access, try using our site map to discover where you can go"
                image={Banner}
            />
        <div id={newStyles.mainImageWrapper}>
            <div className={newStyles.mainImageTextWrapper}>
                {width < breakpoint ?
                    <img src={BannerMobile} id={newStyles.mainImg} alt='Banner' />
                    :
                    <img src={Banner} id={newStyles.mainImg} alt='Banner' />
                }
                    <div className={newStyles.mainImageContent}>
                        <h1 className={newStyles.mainImageTitle}>{t('siteMetadata.404.title')}</h1>
                        <p className={newStyles.mainImageSub}>{t('siteMetadata.404.lost')}</p>
                        <div className={newStyles.learnMoreBuyButtons}>
                            <Link
                                to='/'
                                className={newStyles.cardLearnMoreInline}
                            >
                                {t('siteMetadata.404.return')}
                            </Link>
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className={newStyles.chevronRight}
                            />
                    </div>
                    </div>
                </div>
            </div>
        </Layout>
        </>
    )
}
